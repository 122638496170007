'use client'
import React from 'react'

import HomepageBuilderWrapper from '../../page-builder/homepageBuilder'

const HomePage = ({ footer, header, body, header_image, contact }) => {
  return (
    <>
      <HomepageBuilderWrapper body={body} />
    </>
  )
}

export default HomePage
